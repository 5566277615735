package ui.strings

object GermanStrings : LocalizedStrings {
  override val mondayAbbr = "Mo"
  override val tuesdayAbbr = "Di"
  override val wednesdayAbbr = "Mi"
  override val thursdayAbbr = "Do"
  override val fridayAbbr = "Fr"
  override val saturdayAbbr = "Sa"
  override val sundayAbbr = "So"

  override val january = "Januar"
  override val february = "Februar"
  override val march = "März"
  override val april = "April"
  override val may = "Mai"
  override val june = "Juni"
  override val july = "Juli"
  override val august = "August"
  override val september = "September"
  override val october = "Oktober"
  override val november = "November"
  override val december = "Dezember"

  override val hourSuffix = "Std."
  override val minuteSuffix = "Min."

  override val description = "Beschreibung"
  override val success = "Erfolg"
  override val save = "speichern"
  override val reset = "zurücksetzen"
  override val cancel = "abbrechen"
  override val help = "hilfe"
  override val continueStr = "weiter"
  override val or = "oder"
  override val export = "Exportieren"
  override val exampleFullName = "Hans Müller"

  override val taskCreatorTitle = "Eine neue Aufgabe hinzufügen"
  override val taskTableTotalTime = "Gesamte Zeit"

  override val navMenuTasks = "Aufgaben"
  override val navMenuProjects = "Projekte"
  override val navMenuTeam = "Arbeitsgruppe"
  override val navMenuDetailTeamManagement = "Unternehmensmanagement"
  override val navMenuDetailSwitchTeams = "Arbeitsgruppe ändern"
  override val navMenuDetailSwitchLanguage = "Sprache ändern"
  override val navMenuDetailHelp = "Hilfe"
  override val navMenuDetailLogOut = "Log Out"
  override val teamSelectionExistingTeamButton = "Bestehende Arbeitsgruppe"

  override val loginSubtitle = "Wilkommen zurück bei TRAAK"
  override val loginEmailPlaceholder = "Email"
  override val loginPasswordPlaceholder = "Passwort"
  override val loginLogInButton = "Log In"
  override val loginLogInBexioButton = "Log In mit bexio"
  override val loginNoAccount = "Noch kein Konto?"
  override val loginCreateAccount = "Kreiere eins jetzt"
  override val loginSeparationText = "Oder"
  override val loginExplanationIdentifying =
      "Um sich zu verbinden, geben Sie bitte Ihre E-Mail-Adresse oder Ihre Telefonnummer ein."
  override val loginExplanationValidatingPhone =
      "Bitte geben Sie den Code ein, den Sie per SMS erhalten haben."
  override val loginExplanationWaitingForEmailClick =
      "ie sollten eine E-Mail mit einem Link erhalten haben. Bitte klicken Sie darauf, um sich zu verbinden. Wenn Sie die E-Mail nicht finden, warten Sie ein paar Minuten oder überprüfen Sie Ihren Spam-Ordner."
  override val loginIdentifierError =
      "Die Kennung sollte etwa so aussehen: ‘john.doe@example.org’ oder ‘+41 79 123 45 67’."
  override val loginConnectionTitle = "Verbindung"
  override val loginIdentifierFormIdentifierLabel = "E-Mail oder Telefonnummer"
  override val loginIdentifierFormIdentifierPlaceholder =
      "john.doe@example.org oder +41 79 123 45 67"
  override val loginIdentifierFormIdentifierContinueButton = continueStr
  override val loginValidationFormValidationPlaceholder = "Bestätigungscode"
  override val loginValidationFormValidationLabel = "Bestätigungscode"
  override val loginValidationFormVerifyButton = "überprüfen"

  override val setupPanelTitle = "Einrichtung Ihres Kontos"
  override val setupProgressionName = "Name"
  override val setupProgressionEmail = "E-Mail-Adresse"
  override val setupProgressionPhone = "Telefonnummer"

  override val setupNameTitle = "Wie heißen Sie?"
  override val setupNameExplanation = "Bitte geben Sie unten Ihren vollständigen Namen ein."
  override val setupNameFormNameLabel = "Vollständiger Name"
  override val setupNameFormNamePlaceholder = exampleFullName
  override val setupNameFormContinueButton = continueStr

  override val setupEmailTitle = "Wie lautet Ihre E-Mail-Adresse?"
  override val setupEmailExplanation =
      "Sobald Sie Ihre E-Mail-Adresse unten eingegeben haben, erhalten Sie eine E-Mail. Um sich zu verbinden, klicken Sie bitte auf den Link, den Sie erhalten haben."
  override val setupEmailFormEmailLabel = "E-Mail"
  override val setupEmailFormEmailPlaceholder = "john.doe@example.org"
  override val setupEmailFormContinueButton = continueStr

  override val setupPhoneExplanationRetrieving: String =
      "Bitte geben Sie unten Ihre Telefonnummer ein. Sie erhalten einen SMS-Code, um diese Nummer zu validieren."
  override val setupPhoneExplanationValidating: String =
      "Bitte geben Sie den Code ein, den Sie per SMS erhalten haben."
  override val setupPhoneTitle: String = "Einrichten Ihres Kontos - Telefonnummer"
  override val setupPhoneIdentifierFormPhoneLabel: String = "Telefonnummer"
  override val setupPhoneIdentifierFormPhonePlaceholder: String = "+41 79 123 45 67"
  override val setupPhoneIdentifierFormContinueButton: String = "Weiter"
  override val setupPhoneValidationFormValidationCodePlaceholder: String = "Bestätigungscode"
  override val setupPhoneValidationFormValidationCodeLabel: String = "Bestätigungscode"
  override val setupPhoneValidationFormVerifyButton: String = "Überprüfen"

  override val registerTraakHeadline = "Tritt der TRAAK-Community bei, indem du registrierst"
  override val registerEmailPlaceholder = "Email"
  override val registerFirstNamePlaceholder = "Vorname"
  override val registerLastNamePlaceholder = "Nachname"
  override val registerPasswordPlaceholder = "Passwort"
  override val registerPasswordConfirmationPlaceholder = "Passwort wiederholen"
  override val registerButton = "Registrieren"
  override val registerGoBackButton = "Zurück"
  override val registerErrorEmptyName = "Bitte ganzen Namen einfügen."
  override val registerSuccess =
      "Vielen Dank, dass Sie ein Konto kreiert haben. Sie werden zum Login zurückgeschickt."

  override val authErrorFailureInternal =
      "Sorry, ein interner Fehler ist aufgetreten. Bitte die Seite neu laden."
  override val authErrorFailureUserUnknown = "Dieser Benutzer ist unbekannt."
  override val authErrorFailureUserAlreadyRegistered = "Dieser Benutzer ist schon registriert."
  override val authErrorFailurePasswordTooWeak =
      "Dieses Passwort ist zu schwach. Wähle ein Komplexeres."
  override val authErrorFailurePasswordWrong = "Falsches Passwort versuche es bitte nochmals."
  override val authErrorFailureInvalidEmail = "Diese Emailadresse ist ungültig."
  override val authErrorFailureInvalidPhoneNumber = "Diese Telefonnummer ist ungültig."
  override val authErrorFailureEmailAlreadyInUse = "Diese Emailadresse ist bereits in Gebrauch."
  override val authErrorFailureInvalidVerificationCode = "Der Bestätigungscode ist ungültig."

  override val authErrorFailureUnknown =
      "Ein unbekannter Fehler ist aufgetreten. Kontaktiere bitte das Support-Team."

  override val teamSelectionHeadline =
      "Bitte wähle die Arbeitsgruppe, mit der du dich verbinden möchtest."
  override val teamSelectionLogOut = "Log Out"

  override fun teamSelectionMembers(members: Int): String = "$members Mitglieder"

  override val teamSelectionNoTeamHeadline = "No team is currently linked to your account."
  override val teamSelectionNoTeamInformation =
      "If you should be a part of an existing team, please contact your administrator so that he might give you access. Otherwise, you can create a new team."
  override val teamSelectionNewTeamButton = "Neue Arbeitsgruppe"

  override val requestTeamAccessHeadline =
      "Füge den Identifikations-Code der Arbeitsgruppe ein. Diesen Code solltest du von deinem Administrator erhalten haben."
  override val requestTeamAccessHeadlineSuccess = "Deine Anfrage wurde gespeichert."
  override val requestTeamAccessSuccessMessage =
      "Der Administrator der Arbeitsgruppe muss nun deine Anfrage bestätigen. Sobald dies gemacht wurde, erhältst du Zugang zur Arbeitsgruppe."
  override val requestTeamAccessIdPlaceholder = "Identifikations-Code der Arbeitsgruppe"
  override val requestTeamAccessRequestAccessButton = "Zugang anfordern"
  override val requestTeamAccessRequestFailure =
      "Zugang abgelehnt. Bitte den Identifikations-Code kontrollieren."

  override val teamCreationFailure =
      "Sorry, wir könnten deine Arbeitsgruppe nicht kreieren. Bitte die Seite neu laden oder das Support-Team kontaktieren."
  override val teamCreationMissingData = "Bitte füllen Sie das Formular vollständig aus"

  override val newTeamTraakHeadline =
      "Tritt der TRAAK-Community bei, indem du deine eigene Arbeitsgruppe kreierst."
  override val newTeamCreateButton = "Arbeitsgruppe kreieren"
  override val newTeamCancelButton = "Abbrechen"
  override val newTeamBillingHeadline = "Rechnungsinformationen"
  override val newTeamNamePlaceholder = "Name der Arbeitsgruppe"
  override val newTeamContactNamePlaceholder = "Name des Anspechpartners"
  override val newTeamEmailPlaceholder = "Email des Ansprechpartners"
  override val newTeamPhonePlaceholder = "Telefonnummer des Ansprechpartners"
  override val newTeamAddressPlaceholder = "Rechnungsadresse"
  override val newTeamLocalityPlaceholder = "Ort"
  override val newTeamZipPlaceholder = "Postalzahl"

  override val projectTitleLabel = "Projekttitel"
  override val projectNameLabel = "Name des Kunden"
  override val projectPhoneLabel = "Telefonnummer des Kunden"
  override val projectStreetLabel = "Straße"
  override val projectPostalCodeLabel = "Postleitzahl"
  override val projectLocalityLabel = "Ort"
  override val projectLocalityWithZipLabel = "Ort und Postleitzahl"
  override val projectTitlePlaceholder = "001 Beispiel"
  override val projectNamePlaceholder = "Hans Müller"
  override val projectPhonePlaceholder = "+41 79 123 45 67"
  override val projectStreetPlaceholder = "Bahnhofplatz 15"
  override val projectPostalCodePlaceholder = "8001"
  override val projectLocalityPlaceholder = "Zürich"
  override val projectLocalityWithZipPlaceholder = "8001 Zürich"

  override val projectEmptyTitleError = "Bitte den Pojekttitel einfügen"
  override val projectSavedError = "Ein Fehler ist aufgetreten."

  override val newProjectTitle = "Neues Projekt"
  override val newProjectExplanation =
      "Bitte die notwendigen Informationen einfügen. Du kannst sie jederzeit ändern."
  override val newProjectCreateProjectButton = "Projekt kreieren"

  override val projectOverviewTitle = "Wähle ein Projekt"
  override val projectOverviewNewProjectButton = "Neues Projekt"
  override val projectOverviewSearchBarPlaceholder =
      "Projekte suchen (nach Titel, Name des Kunden, Ort, ...)"
  override val projectOverviewNoActiveProjects =
      "Du hast noch keine Projekte. Du kannst eines kreieren, indem du auf '${projectOverviewNewProjectButton}' klickst."
  override val projectOverviewNoArchivedProjects = "Du hast keine archiviert Projekte."

  override val projectOverviewNoSearchedProjects = "Keine Projekte gefunden..."
  override val projectOverviewSynchronizeBexioTooltipHeader = "bexio synchronisieren"
  override val projectOverviewSynchronizeBexioTooltipParagraph =
      "Du kanst auf Traak kein Projekte mehr erstellen. Aber, du kanst es auf bexio tun."

  override val projectStatusActive = "Aktiv"
  override val projectStatusArchived = "Archiviert"

  override val projectDetailEmptyAddressTitle = "Projektadresse nicht gefunden"
  override val projectDetailEmptyAddressParagraph =
      "Um diese Information hinzuzufügen, bearbeite bitte das Projekt."
  override val projectDetailAddressSubtitle = "Adresse"
  override val projectDetailEditProjectButton = "Projekt bearbeiten"
  override val projectDetailArchiveButton = "Archivieren"
  override val projectDetailRestoreButton = "Wiederherstellen"
  override val projectDetailExportProjectButton = "Exportieren"
  override val projectDetailSearchPlaceholder = "Eine erledigte Arbeit suchen"
  override val projectDetailHeaderDate = "Datum"
  override val projectDetailHeaderUser = "Benutzer"
  override val projectDetailHeaderTask = "Arbeit"
  override val projectDetailHeaderDuration = "Dauer"

  override val projectEditionTitle = "Projekt bearbeiten"
  override val projectEditionExplanation =
      "Modifiziere die unterschiedlichen Attribute und speichere dann."
  override val projectEditionSaveButton = "Speichern"
  override val projectEditionCancelButton = "Abbrechen"

  override val teamOverviewTeamMemberTitle = "Mitglieder der Arbeitsgruppe"
  override val teamOverviewSearchPlaceholder = "Mitglieder suchen"
  override val teamOverviewNoFilteredMember = "Keine Mitglieder gefunden"
  override val teamOverviewSegButtonActive = "Aktiv"
  override val teamOverviewSegButtonRemoved = "Entfernt"

  override val teamSettingsInvitationTitle = "Ein neues Mitglied hinzufügen"
  override val teamSettingsInvitationExplanation =
      "Um ein neues Mitglied hinzuzufügen, kopiere den untenstehenden Identifikations-Code und schicke ihn der gewünschten Person."
  override val teamSettingsInvitationExplanationAfterCopy =
      "Sobald die andere Person eine Mitgliederanfrage gestellt hat, wirst du sie hier unten bestätigen müssen."
  override val teamSettingsRequestsTitle = "Mitgliederanfragen"
  override val teamSettingsInvitationCopied = "Kopiert"
  override val teamSettingsNoRequests = "Keine Anfragen zurzeit."
  override val teamSettingsHeaderName = "Name"
  override val teamSettingsHeaderRole = "Rolle"
  override val teamSettingsRoleAdmin = "Administrator"
  override val teamSettingsRoleProjectManager = "Manager"
  override val teamSettingsRoleMember = "Benutzer"
  override val teamSettingsRoleRemoved = "Desativado"

  override val memberDetailHeaderProject = "Projekt"
  override val memberDetailSearchPlaceholder = "Eine erledigte Arbeit suchen"
  override val memberDetailExport = "Exportieren"

  override val bexioIntegrationHeadline = "Traak mit bexio verbinden"
  override val bexioIntegrationStoringCredentials = "Wir speichern deine Anmeldedaten"
  override val bexioIntegrationSynchronizing =
      "Wir synchronisieren gerade dein Konto mit beixo. Bitte lass diese Seite geöffnet, dies kann eine Weile dauern."
  override val bexioIntegrationError =
      "Entschuldigung, es ist ein Fehler aufgetreten. Bitte lade die Seite neu"
  override val bexioIntegrationDone =
      "Die Synchronisierung deines Kontos ist abgeschlossen. Du wirst bald weitergeleitet werden."
  override val bexioIntegrationSideBarConnection = "Verbindung"
  override val bexioIntegrationSideBarTeamSelection = "Auswahl einer Arbeitsgruppe"
  override val bexioIntegrationSideBarAuthorization = "Berechtigung"
  override val bexioIntegrationSideBarSynchronization = "Synchronisierung"
  override val bexioIntegrationConnectionTitle = "Melde dich mit deinem bexio-Konto an"
  override val bexioIntegrationConnectionExplanation =
      "Um Traak und bexio verbinden zu können, melde dich zunächst mit deinem bexio-Konto an."
  override val bexioIntegrationConnectionBexioExplanation =
      "Wenn du noch kein Konto bei bexio hast, nutze unsere Partnerschaft, um 1 Testmonat sowie 30% Rabatt auf dein erstes Jahr zu erhalten. Für weitere Informationen besuch bitte unsere"
  override val bexioIntegrationConnectionBexioLink = "Informationsseite"
  override val bexioIntegrationTeamSelectionTitle = "Wähle dein Team"
  override val bexioIntegrationTeamSelectionExplanation =
      "Wähle das Team, das du mit beixo verbinden möchtest. Wenn du das Team, das du verbinden möchtest, nicht siehst, kannst du ein neues Team erstellen."
  override val bexioIntegrationAuthorizationTitle = "Zugriff auf Daten erlauben"
  override val bexioIntegrationAuthorizationExplanation =
      "Um dein bexio-Konto mit Traak synchronisieren zu können, müssen wir auf deine Daten zugreifen können. Deshalb wird bexio dich bitten, Traak den Zugriff auf deine Daten zu erlauben."
  override val bexioIntegrationAuthorizationButton = "Erlauben"

  override val taskItemMenuEdit = "Bearbeiten"
  override val taskItemMenuDelete = "Löschen"
  override val taskItemDeletionCancellableSnackbar = "Löschen der Aufgabe abbrechen ?"

  override val memberMenuEdit = "Bearbeiten"
  override val memberMenuDelete = "Löschen"

  override val memberEditionTitle = "Die Name des Mitglieds ändern"
  override val memberEditionFirstNamePlaceholder = "Vorname"
  override val memberEditionLastNamePlaceholder = "Nachname"
  override val memberEditionReset = this.reset
  override val memberEditionSave = this.save

  override val recapListTitle = "Kommunikation"
  override val recapListSeparatorTitle: (String, String) -> String = { start, end ->
    "Von $start bis $end"
  }
  override val recapListEmptyRecap = "Keine Kommunikation"

  override val accountTitle = "Persönliche Einstellungen"
  override val accountDisplaySettingsTitle = "Anzeigeeinstellungen"
  override val accountOrderByFirstNameTitle = "Nach Vornamen ordnen"
  override val accountOrderByFirstNameExplanationActive =
      "Wenn eine Benutzerliste vorgelegt wird, wird diese nach dem Vornamen des Mitarbeiters geordnet."
  override val accountOrderByFirstNameExplanationInactive =
      "Wenn eine Benutzerliste vorgelegt wird, wird diese nach dem Nachnamen des Mitarbeiters geordnet."
  override val accountShowFirstNameFirstTitle = "Den Vornamen zuerst anzeigen"
  override val accountShowFirstNameFirstExplanationActive =
      "Der Vorname des Benutzers wird zuerst angezeigt: Hans Müller"
  override val accountShowFirstNameFirstExplanationInactive =
      "Der Nachname des Benutzers wird zuerst angezeigt: Müller Hans"

  override val searchableCheckboxDeselectAll = "Alles abwählen"
  override val searchableCheckboxSelectAll = "Alles auswählen"

  override val peopleChipTitleEverybody: String = "Alle"
  override val peopleChipTitleNobody: String = "Niemand"

  override fun peopleChipTitle(numberOfPeople: Int): String {
    if (numberOfPeople <= 1) {
      throw IllegalArgumentException("numberOfPeople should be greater than 1.")
    }

    return "$numberOfPeople Personen"
  }

  override val peopleChipSearchPlaceholder = "Nach einem Mitarbeiter suchen"

  override val projectsChipSearchPlaceholder = "Nach einem Projekt suchen"
  override val projectsChipTitleAll: String = "Jedes Projekt"
  override val projectsChipTitleNone: String = "Kein Projekt"

  override fun projectsChipTitle(numberOfProjects: Int): String {
    if (numberOfProjects <= 1) {
      throw IllegalArgumentException("numberOfProjects should be greater than 1.")
    }

    return "$numberOfProjects Projekte"
  }

  override val allTasksTitle = "Alle Aufgaben"
  override val allTasksSearchPlaceholder = "In allen Aufgaben suchen..."
}
