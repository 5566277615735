package navigation

import androidx.compose.runtime.Composable
import api.traak.Member
import api.traak.Project
import api.traak.Team
import app.softwork.routingcompose.NavLink
import app.softwork.routingcompose.Router
import kotlin.time.Duration
import kotlin.time.Duration.Companion.milliseconds
import kotlinx.coroutines.delay
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.jetbrains.compose.web.dom.Text
import org.w3c.dom.HTMLAnchorElement
import tailwind.TailwindScope
import tailwind.TextScope
import tailwind.color.White
import tailwind.tailwind
import ui.color.Primary600
import ui.color.Secondary600

internal const val account = "account"
internal const val team = "team"
internal const val select = "select"
internal const val project = "project"
internal const val tasks = "tasks"
internal const val detail = "detail"
internal const val edit = "edit"
internal const val new = "new"
internal const val request = "request"
internal const val login = "login"
internal const val register = "register"
internal const val signInWithEmailLink = "signInWithEmailLink"

internal const val join = "join"
internal const val integration = "integration"
internal const val bexio = "bexio"
internal const val settings = "settings"
internal const val management = "management"
internal const val memberEdition = "memberEdition"
internal const val projectsUpdate = "projects-update"

sealed class Route(val path: String) {
  // Disconnected
  object Origin : Route("/")

  object Register : Route("/$register")

  class TeamJoin(teamId: Team.Id) : Route("/$team/$join/${teamId.raw}")

  // Authenticated
  object TeamSelection : Route("/$team/$select")

  object NewTeam : Route("/$team/$new")

  object RequestTeamAccess : Route("/$team/$request")

  // Logged In
  object Account : Route("/$account")

  object Tasks : Route("/$tasks")

  object TeamOverview : Route("/$team")

  object TeamManagement : Route("/$team/$management")

  object ProjectsUpdate : Route("/$team/$management/$projectsUpdate")

  class MemberEdition(member: Member) : Route("/$team/$management/$memberEdition/${member.id.raw}")

  class MemberDetail(memberId: String) : Route("/$team/$detail/$memberId")

  object ProjectOverview : Route("/$project")

  object NewProject : Route("/$project/$new")

  class ProjectDetail(projectId: Project.Id) : Route("/$project/$detail/${projectId.raw}")

  class ProjectEdition(projectId: Project.Id) : Route("/$project/$edit/${projectId.raw}")

  // Integration
  object IntegrationBexio : Route("/$integration/$bexio")

  override fun toString(): String {
    return path
  }
}

fun Router.navigate(to: Route) = navigate(to.toString())

suspend fun Router.delayedNavigate(
    to: Route,
    duration: Duration = 100.milliseconds,
) {
  delay(duration)
  this.navigate(to)
}

@Composable
fun RouteLink(
    to: Route,
    text: String,
    attrs: AttrBuilderContext<HTMLAnchorElement>? = null,
) {
  NavLink(
      attrs = {
        attrs?.invoke(this)

        tailwind {
          text(color = Primary600)
          cursor(TailwindScope.Cursor.Pointer)
          hover { text(decoration = TextScope.Decoration.Underline) }
        }
      },
      to = to.toString(),
  ) {
    Text(text)
  }
}

@Composable
fun RouteLinkButton(
    to: Route,
    text: String,
    attrs: AttrBuilderContext<HTMLAnchorElement>? = null,
) {
  NavLink(
      attrs = {
        attrs?.invoke(this)

        tailwind {
          p(4)
          background(color = White)
          text(color = Secondary600) { button(size = TextScope.Size.Base) }
          hover { text(decoration = TextScope.Decoration.Underline) }
          cursor(TailwindScope.Cursor.Pointer)
        }
      },
      to = to.toString(),
  ) {
    Text(text)
  }
}
