package ui.screens.projectsUpdate

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Li
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Text
import org.jetbrains.compose.web.dom.Ul
import tailwind.Full
import tailwind.TailwindScope
import tailwind.TextScope
import tailwind.color.Green600
import tailwind.color.Red050
import tailwind.tailwind
import ui.color.ErrorLight
import ui.components.PageTitle
import ui.icons.Check
import ui.icons.Close
import ui.layouts.HalfScreenLayout
import ui.material.FullColumn
import ui.material.FullRow
import ui.strings.LocalStrings

@Composable
fun Finished(
    successfulUpdates: Int,
    errors: List<String>,
) {
  val strings = LocalStrings.current

  HalfScreenLayout {
    PageTitle(title = strings.projectsUpdateFinishedTitle, attrs = { tailwind { mb(10) } })

    FullColumn(
        gap = 10,
        attrs = { tailwind { text { body1() } } },
    ) {
      FullColumn(gap = 10) {
        if (successfulUpdates > 0) {
          FullRow(gap = 4) {
            Check(attrs = { tailwind { text(color = Green600) } })
            P { Text(strings.projectsUpdateFinishedSuccessfullyApplied(successfulUpdates)) }
          }
        }

        if (errors.isNotEmpty()) {
          FullColumn(gap = 4) {
            FullRow(gap = 4) {
              Close(attrs = { tailwind { text(color = ErrorLight) } })
              P { Text(strings.projectsUpdateFinishedUnsuccessfullyApplied(errors.size)) }
            }

            Div(attrs = { tailwind { w(Full) } }) {
              P(
                  attrs = { tailwind { text { body1(size = TextScope.Size.Lg) } } },
              ) {
                Text(strings.projectsUpdateFinishedUnsuccessfulManualUpdate)
              }

              Ul(
                  attrs = {
                    tailwind {
                      w(Full)
                      py(2)
                      px(8)
                      round(TailwindScope.Radius.Xl)
                      background(color = Red050)
                      border(color = ErrorLight, width = 1)
                      listStyle(TailwindScope.ListStyle.Disc)

                      text { body1(size = TextScope.Size.Base) }
                    }
                  },
              ) {
                errors.forEach { Li { Text(it) } }
              }
            }
          }
        }
      }
    }
  }
}
