package ui.screens.projectsUpdate

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.jetbrains.compose.web.dom.Li
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text
import org.jetbrains.compose.web.dom.Ul
import org.w3c.dom.HTMLDivElement
import tailwind.FlexItemScope
import tailwind.Layout
import tailwind.TailwindScope
import tailwind.TextScope
import tailwind.color.White
import tailwind.tailwind
import ui.color.BlackMedium
import ui.color.ErrorMedium
import ui.color.Primary600
import ui.color.Secondary200
import ui.components.PageTitle
import ui.layouts.HalfScreenLayout
import ui.material.FullColumn
import ui.material.FullRow
import ui.material.Spinner
import ui.strings.LocalStrings

@Composable
fun ApplyUpdates(
    currentUpdate: Int,
    totalUpdate: Int,
    message: String,
    errors: List<String>,
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
) {
  val strings = LocalStrings.current

  HalfScreenLayout(attrs) {
    PageTitle(
        title = strings.projectsUpdateApplyTitle,
        attrs = { tailwind { flexItem(FlexItemScope.Grow.Grow) } },
    )

    P(attrs = { tailwind { text(color = BlackMedium) { subtitle1() } } }) {
      Text(strings.projectsUpdateApplyLoading)
    }

    Loading(
        current = currentUpdate,
        total = totalUpdate,
        message = message,
        errors = errors,
    )
  }
}

@Composable
fun Loading(
    current: Int,
    total: Int,
    message: String,
    errors: List<String>,
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
) {
  val strings = LocalStrings.current

  FullColumn(
      justify = Layout.Justify.Center,
      alignItems = Layout.AlignItems.Center,
      gap = 10,
      attrs = {
        attrs?.invoke(this)
        tailwind {
          mt(10)

          border(color = Secondary200, width = 1)
          round(TailwindScope.Radius.Large)
          background(color = White)
          p(10)
        }
      },
  ) {
    FullRow(gap = 10, justify = Layout.Justify.Center, alignItems = Layout.AlignItems.Center) {
      Spinner(size = 10, attrs = { tailwind { text(color = Primary600) } })

      P(
          attrs = { tailwind { text { subtitle2() } } },
      ) {
        Text(strings.projectsUpdateApplyLoadingSteps)

        Span(
            attrs = {
              tailwind {
                ml(4)
                text(color = BlackMedium)
              }
            },
        ) {
          Text("($current/$total)")
        }
      }
    }

    FullRow(
        alignItems = Layout.AlignItems.Center,
        justify = Layout.Justify.Center,
    ) {
      P(
          attrs = {
            tailwind { text(color = BlackMedium) { subtitle1(size = TextScope.Size.Lg) } }
          },
      ) {
        Text(message)
      }
    }

    FullColumn(justify = Layout.Justify.Start, alignItems = Layout.AlignItems.Start) {
      Ul(
          attrs = {
            tailwind {
              listStyle(TailwindScope.ListStyle.Disc)
              text(color = ErrorMedium) { subtitle1(size = TextScope.Size.Base) }
            }
          },
      ) {
        errors.forEach { Li { Text(it) } }
      }
    }
  }
}
