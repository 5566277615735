package ui.screens.projectsUpdate

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Text
import org.w3c.dom.HTMLDivElement
import tailwind.Layout
import tailwind.Side
import tailwind.TailwindScope
import tailwind.color.White
import tailwind.tailwind
import ui.color.BlackMedium
import ui.material.FilledButton
import ui.material.FullRow
import ui.strings.LocalStrings

@Composable
fun BottomBar(
    selectedProjects: Int,
    onUpdateClick: () -> Unit,
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
) {
  val strings = LocalStrings.current

  Div(
      attrs = {
        attrs?.invoke(this)

        tailwind {
          position(TailwindScope.Position.Fixed)
          bottom(0)
          left(0)
          w(TailwindScope.Width.Screen)
          shadow(TailwindScope.Shadow.Xl2)
          background(White)
          border(
              scope = { 1.width(Side.Top) },
          )
        }
      },
  ) {
    FullRow(
        justify = Layout.Justify.Center,
        alignItems = Layout.AlignItems.Center,
        gap = 4,
        attrs = { tailwind { p(4) } },
    ) {
      Div(
          attrs = { tailwind { text(color = BlackMedium) { subtitle1() } } },
      ) {
        Text(strings.projectsUpdateBottomBarSelectedProjects(selectedProjects))
      }

      Div(attrs = { tailwind { w(64) } }) {}

      FilledButton(text = strings.projectsUpdateBottomBarUpdateButton, onClick = onUpdateClick)
    }
  }
}
