package ui.screens.projectsUpdate

enum class Stage {
  Select,
  Apply,
  Finished,
}

data class SelectableProject(
    val id: String,
    val title: String,
    val selected: Boolean,
)

interface ProjectsUpdateState {
  val stage: Stage
  val allProjectsAreSelected: Boolean

  // Projects
  val loading: Boolean
  val projects: List<SelectableProject>

  fun toggle(project: SelectableProject)

  fun selectAll()

  fun deselectAll()

  // Search
  var search: String

  // Bottom bar
  val selectedProjectsAmount: Int

  fun updateSelectedProjects()

  // Updates
  val currentMessage: String
  val currentUpdate: Int
  val totalUpdates: Int

  val updateErrors: List<String>
}
