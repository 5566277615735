package ui.screens.teamManagement

import androidx.compose.runtime.Composable
import api.traak.Team
import api.traak.user.User
import navigation.Route
import navigation.RouteLinkButton
import org.jetbrains.compose.web.attributes.ATarget
import org.jetbrains.compose.web.attributes.target
import org.jetbrains.compose.web.dom.A
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Text
import org.w3c.dom.HTMLDivElement
import tailwind.FlexScope
import tailwind.Full
import tailwind.Layout
import tailwind.TailwindScope
import tailwind.tailwind
import tailwind.twelfth
import ui.color.BlackLight
import ui.color.BlackMedium
import ui.components.PageTitle
import ui.icons.HelpCircleOutline
import ui.layouts.FullScreenLayout
import ui.material.Column
import ui.material.FullRow
import ui.material.Row
import ui.strings.LocalStrings

@Composable
fun TeamManagement(
    currentUser: User,
    team: Team,
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
) {

  FullScreenLayout(attrs) {
    Row(
        alignItems = Layout.AlignItems.Start,
        gap = 4,
        attrs = { tailwind { pt(8) } },
    ) {
      Column(gap = 4, attrs = { tailwind { w(5.twelfth) } }) {
        val sharedAttrs: AttrBuilderContext<HTMLDivElement> = { tailwind { w(Full) } }

        Invitation(
            teamId = team.id,
            attrs = sharedAttrs,
        )

        Requests(
            team = team,
            attrs = sharedAttrs,
        )

        ProjectsUpdateLink(
            attrs = sharedAttrs,
        )
      }

      Column(attrs = { tailwind { w(7.twelfth) } }) { TeamMembers(currentUser, team) }
    }
  }
}

@Composable
fun ProjectsUpdateLink(
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
) {
  val strings = LocalStrings.current

  Column(
      justify = Layout.Justify.Start,
      alignItems = Layout.AlignItems.Start,
      attrs = {
        attrs?.invoke(this)
        tailwind { mt(4) }
      },
  ) {
    PageTitle(
        attrs = {
          tailwind {
            w(Full)
            display(TailwindScope.Display.Block)
            flex(
                direction = FlexScope.Direction.Row,
                alignItems = Layout.AlignItems.Center,
                justify = Layout.Justify.Start,
                gap = 4,
            )
          }
        },
    ) {
      Text(strings.teamSettingsProjectsUpdateTitle)

      A(
          href = "https://www.traak.ch/help/yearly-project-update/",
          attrs = {
            target(ATarget.Blank)
            tailwind { text(color = BlackLight) }
          },
      ) {
        HelpCircleOutline()
      }
    }

    P(attrs = { tailwind { text(color = BlackMedium) { subtitle1() } } }) {
      Text(strings.teamSettingsProjectsUpdateExplanation)
    }

    FullRow {
      RouteLinkButton(
          to = Route.ProjectsUpdate,
          text = strings.teamSettingsProjectsUpdateLink,
      )
    }
  }
}
