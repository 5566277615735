package api.traak

interface Project : Task.Project {
  /** The project's unique Id */
  override val id: Id

  /** The project's title */
  override val title: String

  /** The status of the project */
  override val status: Status

  /** The project's address */
  val address: Address

  suspend fun changeStatus(to: Status): StorageResult

  data class Id(val raw: String)

  interface Address {
    /** The client's name */
    val name: String

    /** The client's phone number */
    val phone: String

    /** The client's street */
    val place: String

    /** The client's locality */
    val locality: String
  }

  enum class Status {
    Active,
    Archived
  }
}
