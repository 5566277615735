package ui.components.taskCreator

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.mutableStateListOf
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import api.traak.LocalTraakApi
import api.traak.Member
import api.traak.Project
import api.traak.Task
import api.traak.Team
import api.traak.TraakApi
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch

private const val CLOSE_DELAY = 1000L

sealed class EditionMode {
  object New : EditionMode()

  class Edit(val taskId: Task.Id) : EditionMode()
}

fun Task.toEditionState(): InnerTaskEditionState {
  return InnerTaskEditionStateHolder(
      originalTask = this,
      initialProject = project,
      initialMember = author,
      initialDescription = this.description,
      initialStartDate = this.start,
      initialEndDate = this.end,
  )
}

class TaskCreatorStateHolder(
    private val team: Team,
    private val coroutineScope: CoroutineScope,
    private val api: TraakApi,
    private val closeDelay: Long? = null,
) : TaskCreatorState, TaskEditionState {
  private var editionMode: EditionMode = EditionMode.New
  private val editionState = mutableStateOf<InnerTaskEditionState>(createEmptyInnerTaskState())

  private val active = mutableStateOf(false)
  private val _loading = mutableStateOf(false)
  private val _success = mutableStateOf(false)

  private var initialProject: Project? = null
  private var initialMember: Member? = null

  private val _projects = mutableStateListOf<Project>()
  private val _members = mutableStateListOf<Member>()

  init {
    coroutineScope.launch {
      launch {
        team.getProjects(Project.Status.Active).collect {
          _projects.clear()
          _projects.addAll(it)
        }
      }

      launch {
        team.members.collect {
          _members.clear()
          _members.addAll(it)
        }
      }
    }
  }

  override val loading: Boolean
    get() = _loading.value

  override val success: Boolean
    get() = _success.value

  override val modalIsActive: Boolean
    get() = active.value

  override val projects: List<Project>
    get() = _projects

  override val members: List<Member>
    get() = _members

  override fun onCloseClick() {
    active.value = false
  }

  override fun onFABClick() {
    editionMode = EditionMode.New
    editionState.value = createEmptyInnerTaskState()
    active.value = true
  }

  override fun saveTask() {
    if (!editionState.value.creationIsValid) {
      return
    }

    coroutineScope.launch {
      _loading.value = true

      val dto = editionState.value.toEditionDto()

      when (editionMode) {
        EditionMode.New ->
            api.createTask(
                teamId = team.id,
                taskEditionDto = dto,
            )
        is EditionMode.Edit ->
            api.editTask(
                taskId = (editionMode as EditionMode.Edit).taskId,
                taskEditionDto = dto,
            )
      }

      _loading.value = false
      _success.value = true

      delay(closeDelay ?: CLOSE_DELAY)

      editionMode = EditionMode.New
      editionState.value = createEmptyInnerTaskState()
      active.value = false
      _success.value = false
    }
  }

  override fun edit(task: Task) {
    editionMode = EditionMode.Edit(task.id)
    editionState.value = task.toEditionState()
    active.value = true
  }

  // Updatable properties
  fun updateInitialProject(project: Project?) {
    initialProject = project
  }

  fun updateInitialMember(member: Member?) {
    initialMember = member
  }

  private fun createEmptyInnerTaskState(): InnerTaskCreationStateHolder {
    return InnerTaskCreationStateHolder(
        initialProject = initialProject,
        initialMember = initialMember,
    )
  }

  // Edition state
  // Cannot use property delegation because element would not be updated

  override var currentProject: Task.Project?
    get() = editionState.value.currentProject
    set(value) {
      editionState.value.currentProject = value
    }

  override var selectedAuthor: Task.Author?
    get() = editionState.value.selectedAuthor
    set(value) {
      editionState.value.selectedAuthor = value
    }

  override var taskDate: String
    get() = editionState.value.taskDate
    set(value) {
      editionState.value.taskDate = value
    }

  override var taskDuration: String
    get() = editionState.value.taskDuration
    set(value) {
      editionState.value.taskDuration = value
    }

  override var taskDescription: String
    get() = editionState.value.taskDescription
    set(value) {
      editionState.value.taskDescription = value
    }

  override val creationIsValid: Boolean
    get() = editionState.value.creationIsValid

  override fun reset() {
    editionState.value.reset()
  }
}

@Composable
fun rememberTaskCreatorState(
    team: Team,
    initialProject: Project? = null,
    initialMember: Member? = null,
    coroutineScope: CoroutineScope = rememberCoroutineScope(),
    api: TraakApi = LocalTraakApi.current,
    closeDelay: Long? = null,
): TaskCreatorStateHolder {
  val creatorState =
      remember(
          coroutineScope,
          api,
      ) {
        TaskCreatorStateHolder(
            team = team,
            coroutineScope = coroutineScope,
            api = api,
            closeDelay = closeDelay,
        )
      }

  // Do not recreate state when some properties change, but update them
  // This prevents the state from being recreated while the user is typing

  LaunchedEffect(initialMember, initialProject) {
    creatorState.updateInitialMember(initialMember)
    creatorState.updateInitialProject(initialProject)
  }

  return creatorState
}
