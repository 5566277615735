package ui.screens.teamOverview

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.setValue
import api.settings.LocalSettings
import api.settings.SettingsApi
import api.traak.Member
import api.traak.Role
import api.traak.Team
import app.softwork.routingcompose.Router
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.launch
import navigation.Route
import navigation.navigate
import ui.data.Loadable
import ui.data.asLoadable
import ui.data.filter
import ui.data.sortedWith
import ui.data.toList
import ui.material.SegmentedButtonItem
import ui.strings.LocalizedStrings

enum class SegmentedButtonSelection {
  Active,
  Inactive,
}

class TeamOverviewStateHolder(
    private val team: Team,
    private val settingsApi: SettingsApi,
    private val coroutineScope: CoroutineScope,
    private val router: Router,
) : TeamOverviewState {
  private var selectedSegmentedButton by mutableStateOf(SegmentedButtonSelection.Active)
  private var _loadableMembers: Loadable<List<Member>> by mutableStateOf(Loadable.Loading)

  init {
    coroutineScope.launch { team.members.asLoadable().collect { _loadableMembers = it } }
  }

  private val memberComparator: Comparator<Member> =
      compareBy(
          { firstOrderElementOfMember(it) },
          { secondOrderElementOfMember(it) },
      )

  override fun segmentedButtons(strings: LocalizedStrings): List<SegmentedButtonItem> =
      listOf(
          SegmentedButtonItem(
              text = strings.teamOverviewSegButtonActive,
              onClick = { selectedSegmentedButton = SegmentedButtonSelection.Active },
              active = selectedSegmentedButton == SegmentedButtonSelection.Active,
          ),
          SegmentedButtonItem(
              text = strings.teamOverviewSegButtonRemoved,
              onClick = { selectedSegmentedButton = SegmentedButtonSelection.Inactive },
              active = selectedSegmentedButton == SegmentedButtonSelection.Inactive,
          ),
      )

  override val loadableMembers: Loadable<List<Member>>
    get() = _loadableMembers.filter { it.matchesSegmentedButton() }.sortedWith(memberComparator)

  override val filteredMembers: List<Member>
    get() = loadableMembers.filter { it.contains(search) }.toList()

  override var search by mutableStateOf("")

  override fun onMemberClick(member: Member) {
    router.navigate(Route.MemberDetail(member.id.raw))
  }

  private fun Member.matchesSegmentedButton(): Boolean {
    return if (selectedSegmentedButton == SegmentedButtonSelection.Active) {
      this.role != Role.REMOVED
    } else {
      this.role == Role.REMOVED
    }
  }

  private fun Member.contains(value: String): Boolean =
      this.firstName.contains(value, ignoreCase = true) ||
          this.lastName.contains(value, ignoreCase = true)

  private fun firstOrderElementOfMember(member: Member): String {
    return if (settingsApi.orderByFirstName.value) {
      member.firstName
    } else {
      member.lastName
    }
  }

  private fun secondOrderElementOfMember(member: Member): String {
    return if (settingsApi.orderByFirstName.value) {
      member.lastName
    } else {
      member.firstName
    }
  }
}

@Composable
fun rememberTeamOverviewState(
    team: Team,
    settingsApi: SettingsApi = LocalSettings.current,
    coroutineScope: CoroutineScope = rememberCoroutineScope(),
    router: Router = Router.current,
): TeamOverviewState {
  return remember {
    TeamOverviewStateHolder(
        team = team,
        settingsApi = settingsApi,
        coroutineScope = coroutineScope,
        router = router,
    )
  }
}
