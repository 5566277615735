package api.traak

import api.traak.Traak.Companion.Projects
import api.traak.toFirestore.toFirestoreField
import firebase.firestore.DocumentData
import firebase.firestore.DocumentReference
import firebase.firestore.Firestore
import firebase.firestore.doc
import firebase.firestore.updateDoc
import kotlinx.coroutines.await
import kotlinx.serialization.Serializable

class TraakProject(
    private val firestore: Firestore,
    override val id: Project.Id,
    override val title: String,
    override val status: Project.Status,
    override val address: Project.Address
) : Project {

  override suspend fun changeStatus(to: Project.Status): StorageResult {
    @Serializable
    data class UpdateProjectStatus(
        val status: String,
    )

    val data = UpdateProjectStatus(to.toFirestoreField())

    return try {
      updateDoc(
              reference = Projects.ref(id),
              data = data.asFirestoreObject(),
          )
          .await()

      StorageResult.Success
    } catch (t: Throwable) {
      console.error(t.message)
      t.toStorageResult()
    }
  }

  private fun Projects.ref(projectId: Project.Id): DocumentReference<DocumentData> =
      doc(firestore, "${path}/${projectId.raw}")
}
