package ui.screens.projectsUpdate

import androidx.compose.runtime.Composable
import api.traak.Team
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.w3c.dom.HTMLDivElement

@Composable
fun ProjectsUpdate(
    team: Team,
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
) {
  val state = rememberProjectsUpdateState(team)

  when (state.stage) {
    Stage.Select ->
        SelectProjects(
            loading = state.loading,
            projects = state.projects,
            onProjectClick = { state.toggle(it) },
            allProjectsAreSelected = state.allProjectsAreSelected,
            onSelectAllClick = { state.selectAll() },
            onDeselectAllClick = { state.deselectAll() },
            search = state.search,
            onSearchInput = { state.search = it },
            selectedProjects = state.selectedProjectsAmount,
            onUpdateClick = { state.updateSelectedProjects() },
            attrs = attrs,
        )
    Stage.Apply ->
        ApplyUpdates(
            currentUpdate = state.currentUpdate,
            totalUpdate = state.totalUpdates,
            message = state.currentMessage,
            errors = state.updateErrors,
            attrs = attrs,
        )
    Stage.Finished ->
        Finished(
            successfulUpdates = state.totalUpdates - state.updateErrors.size,
            errors = state.updateErrors,
        )
  }
}
