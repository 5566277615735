package ui.screens.projectsUpdate

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.attributes.ATarget
import org.jetbrains.compose.web.attributes.target
import org.jetbrains.compose.web.dom.A
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Text
import org.w3c.dom.HTMLDivElement
import tailwind.FlexScope
import tailwind.Full
import tailwind.Layout
import tailwind.TailwindScope
import tailwind.tailwind
import ui.color.BlackLight
import ui.color.BlackMedium
import ui.components.PageTitle
import ui.icons.HelpCircleOutline
import ui.layouts.HalfScreenLayout
import ui.material.FullColumn
import ui.material.FullRow
import ui.material.OutlineButton
import ui.material.SearchInput
import ui.screens.projectOverview.Loading
import ui.strings.LocalStrings

@Composable
fun SelectProjects(
    loading: Boolean,
    projects: List<SelectableProject>,
    onProjectClick: (SelectableProject) -> Unit,
    allProjectsAreSelected: Boolean,
    onSelectAllClick: () -> Unit,
    onDeselectAllClick: () -> Unit,
    // Search
    search: String,
    onSearchInput: (String) -> Unit,
    // Bottom bar
    selectedProjects: Int,
    onUpdateClick: () -> Unit,
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
) {
  val strings = LocalStrings.current

  HalfScreenLayout(attrs) {
    PageTitle(
        attrs = {
          tailwind {
            w(Full)
            display(TailwindScope.Display.Block)
            flex(
                direction = FlexScope.Direction.Row,
                alignItems = Layout.AlignItems.Center,
                justify = Layout.Justify.Start,
                gap = 4,
            )
          }
        },
    ) {
      Text(strings.projectsUpdateSelectTitle)

      A(
          href = "https://www.traak.ch/help/yearly-project-update/",
          attrs = {
            target(ATarget.Blank)
            tailwind { text(color = BlackLight) }
          },
      ) {
        HelpCircleOutline()
      }
    }

    FullColumn(gap = 4) {
      P(attrs = { tailwind { text(color = BlackMedium) { subtitle1() } } }) {
        Text(strings.projectsUpdateSelectExplanation)
      }
      P(attrs = { tailwind { text(color = BlackMedium) { subtitle1() } } }) {
        Text(strings.projectsUpdateSelectExplanation2)
      }
    }

    FullColumn(gap = 4, attrs = { tailwind { mt(6) } }) {
      FullRow(justify = Layout.Justify.End) {
        OutlineButton(
            text =
                if (allProjectsAreSelected) strings.searchableCheckboxDeselectAll
                else strings.searchableCheckboxSelectAll,
            onClick = if (allProjectsAreSelected) onDeselectAllClick else onSelectAllClick,
        )
      }

      SearchInput(
          value = search,
          onInput = onSearchInput,
          placeholder = strings.projectsUpdateSelectSearchPlaceholder,
      )

      if (loading) Loading() else ProjectsList(projects = projects, onProjectClick = onProjectClick)
    }

    Div(attrs = { tailwind { h(32) } }) {}

    BottomBar(
        selectedProjects = selectedProjects,
        onUpdateClick = onUpdateClick,
    )
  }
}
