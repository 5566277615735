package api.traak.fromFirestore

import api.traak.Project
import api.traak.TraakProject
import api.traak.toFirestore.Address
import firebase.firestore.DocumentData
import firebase.firestore.Firestore
import kotlinx.serialization.Serializable
import kotlinx.serialization.json.Json

@Serializable
data class FromFirestoreProject(
    val id: String,
    val title: String,
    val address: Address,
    val status: String? = null,
) {
  companion object {
    private val json = Json { ignoreUnknownKeys = true }

    fun withData(data: DocumentData): FromFirestoreProject =
        json.decodeFromString(JSON.stringify(data))
  }
}

fun FromFirestoreProject.toProject(
    firestore: Firestore,
): Project =
    TraakProject(
        firestore = firestore,
        id = Project.Id(raw = this.id),
        title = this.title,
        status = this.status?.toProjectStatus() ?: Project.Status.Active,
        address = this.address,
    )

fun String.toProjectStatus(): Project.Status =
    when (this) {
      "active" -> Project.Status.Active
      "archived" -> Project.Status.Archived
      else -> Project.Status.Active
    }
