package ui.icons

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.attributes.AttrsScope
import org.jetbrains.compose.web.css.CSSSizeValue
import org.jetbrains.compose.web.css.CSSUnit
import org.jetbrains.compose.web.css.height
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.width
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.jetbrains.compose.web.dom.Div
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.svg.SVGPathElement

val DEFAULT_ICON_SIZE = 24.px

@Composable
internal fun Icon(
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
    size: CSSSizeValue<CSSUnit.px> = DEFAULT_ICON_SIZE,
    pathAttr: AttrsScope<SVGPathElement>.() -> Unit,
) =
    Div(attrs) {
      Svg(
          attrs = {
            attr("width", size.toString())
            attr("height", size.toString())
            attr("viewBox", "0 0 24 24")
            attr("fill", "currentColor")
          },
      ) {
        Path(attrs = pathAttr)
      }
    }

@Composable
fun IconPlaceholder(
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
) {
  Div(
      attrs = {
        attrs?.invoke(this)
        style {
          width(24.px)
          height(24.px)
        }
      },
  )
}
