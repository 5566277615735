package ui.screens.projectsUpdate

import CheckboxBlankOutline
import CheckboxMarkedOutline
import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text
import org.w3c.dom.HTMLDivElement
import tailwind.Full
import tailwind.TailwindScope
import tailwind.TextScope
import tailwind.tailwind
import ui.color.BlackMedium
import ui.material.Card
import ui.material.ClickableListItem
import ui.material.FullRow

@Composable
fun ProjectsList(
    projects: List<SelectableProject>,
    onProjectClick: (SelectableProject) -> Unit,
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
) {
  Card(
      attrs = {
        attrs?.invoke(this)
        tailwind { w(Full) }
      },
  ) {
    projects.forEach { project ->
      ClickableListItem(
          onClick = { onProjectClick(project) },
          attrs = { tailwind { w(Full) } },
      ) {
        FullRow(gap = 4, attrs = { tailwind { select(TailwindScope.SelectType.None) } }) {
          Div(
              attrs = { tailwind { text(color = BlackMedium) } },
          ) {
            if (project.selected) {
              CheckboxMarkedOutline()
            } else {
              CheckboxBlankOutline()
            }
          }

          Span(
              attrs = { tailwind { text { body1(weight = TextScope.Weight.Semibold) } } },
          ) {
            Text(project.title)
          }
        }
      }
    }
  }
}
