package ui.strings

object FrenchStrings : LocalizedStrings {
  override val mondayAbbr = "Lun"
  override val tuesdayAbbr = "Mar"
  override val wednesdayAbbr = "Mer"
  override val thursdayAbbr = "Jeu"
  override val fridayAbbr = "Ven"
  override val saturdayAbbr = "Sam"
  override val sundayAbbr = "Dim"

  override val january = "Janvier"
  override val february = "Février"
  override val march = "Mars"
  override val april = "Avril"
  override val may = "Mai"
  override val june = "Juin"
  override val july = "Juillet"
  override val august = "Août"
  override val september = "Septembre"
  override val october = "Octobre"
  override val november = "Novembre"
  override val december = "Décembre"

  override val hourSuffix = "h"
  override val minuteSuffix = "m"

  override val description = "description"
  override val success = "succès"
  override val save = "sauvegarder"
  override val reset = "réinitialiser"
  override val cancel = "annuler"
  override val help = "aide"
  override val continueStr = "continuer"
  override val or = "ou"
  override val export = "Exporter"

  override val exampleFullName = "Jean Dupond"

  override val taskCreatorTitle = "Ajouter une nouvelle tâche"

  override val taskTableTotalTime = "Temps total"

  override val navMenuTasks = "Tâches"
  override val navMenuProjects = "Projets"
  override val navMenuTeam = "Entreprise"
  override val navMenuDetailTeamManagement = "Gestion d'entreprise"
  override val navMenuDetailSwitchTeams = "Changer d'entreprise"
  override val navMenuDetailSwitchLanguage = "Changer de langue"
  override val navMenuDetailHelp = "Aide"
  override val navMenuDetailLogOut = "Se déconnecter"

  override val loginSubtitle = "Bienvenue sur TRAAK"
  override val loginEmailPlaceholder = "Email"
  override val loginPasswordPlaceholder = "Mot de passe"
  override val loginLogInButton = "Se connecter"
  override val loginLogInBexioButton = "Se connecter avec bexio"
  override val loginNoAccount = "Pas de compte ?"
  override val loginCreateAccount = "Inscrivez-vous"
  override val loginSeparationText = "Ou"
  override val loginExplanationIdentifying =
      "Pour vous connecter, veuillez entrer votre adresse email ou votre numéro de téléphone."
  override val loginExplanationValidatingPhone =
      "Veuillez entrer le code que vous avez reçu par SMS."
  override val loginExplanationWaitingForEmailClick =
      "Vous devriez avoir reçu un email contenant un lien. Veuillez cliquer dessus pour vous connecter. Si vous ne trouvez pas l’email, attendez quelques minutes ou vérifiez votre dossier spam."
  override val loginIdentifierError =
      "L’identifiant devrait ressembler à ‘john.doe@example.org’ ou ‘+41 79 123 45 67’."
  override val loginConnectionTitle = "Connexion"
  override val loginIdentifierFormIdentifierLabel = "Email ou numéro de téléphone"
  override val loginIdentifierFormIdentifierPlaceholder = "john.doe@example.org ou +41 79 123 45 67"
  override val loginIdentifierFormIdentifierContinueButton = continueStr
  override val loginValidationFormValidationPlaceholder = "Code de validation"
  override val loginValidationFormValidationLabel = "Code de validation"
  override val loginValidationFormVerifyButton = "Vérifier"

  override val setupPanelTitle = "Configuration de votre compte"
  override val setupProgressionName = "Nom"
  override val setupProgressionEmail = "Adresse email"
  override val setupProgressionPhone = "Numéro de téléphone"

  override val setupNameTitle = "Quel est votre nom ?"
  override val setupNameExplanation = "Veuillez entrer votre nom complet ci-dessous."
  override val setupNameFormNameLabel = "Nom complet"
  override val setupNameFormNamePlaceholder = exampleFullName
  override val setupNameFormContinueButton = continueStr

  override val setupPhoneExplanationRetrieving: String =
      "Veuillez entrer votre numéro de téléphone ci-dessous. Vous recevrez un code par SMS afin de valider ce numéro."
  override val setupPhoneExplanationValidating: String =
      "Veuillez entrer le code que vous avez reçu par message texte."
  override val setupPhoneTitle: String = "Configuration de votre compte - Numéro de téléphone"
  override val setupPhoneIdentifierFormPhoneLabel: String = "Numéro de téléphone"
  override val setupPhoneIdentifierFormPhonePlaceholder: String = "+41 79 123 45 67"
  override val setupPhoneIdentifierFormContinueButton: String = "Continuer"
  override val setupPhoneValidationFormValidationCodePlaceholder: String = "Code de validation"
  override val setupPhoneValidationFormValidationCodeLabel: String = "Code de validation"
  override val setupPhoneValidationFormVerifyButton: String = "Vérifier"

  override val setupEmailTitle = "Quelle est votre adresse email ?"
  override val setupEmailExplanation =
      "Une fois que vous aurez entré votre adresse email ci-dessous, vous recevrez un email. Pour vous connecter, veuillez cliquer sur le lien que vous aurez reçu."
  override val setupEmailFormEmailLabel = "Email"
  override val setupEmailFormEmailPlaceholder = "john.doe@example.org"
  override val setupEmailFormContinueButton = continueStr

  override val registerTraakHeadline = "Rejoignez la communauté TRAAK"
  override val registerEmailPlaceholder = "Email"
  override val registerFirstNamePlaceholder = "Prénom"
  override val registerLastNamePlaceholder = "Nom de famille"
  override val registerPasswordPlaceholder = "Mot de passe"
  override val registerPasswordConfirmationPlaceholder = "Confirmation du mot de passe"
  override val registerButton = "S'enregistrer"
  override val registerGoBackButton = "Retour"
  override val registerErrorEmptyName = "Veuillez entrer votre nom."
  override val registerSuccess =
      "Merci d'avoir créé un compte. Vous allez être redirigés vers la page de connection."

  override val authErrorFailureInternal = "Désolé, nous avons eu une erreur interne."
  override val authErrorFailureUserUnknown = "Cet utilisateur n'existe pas."
  override val authErrorFailureUserAlreadyRegistered = "Cet utilisateur existe déjà"
  override val authErrorFailurePasswordTooWeak =
      "Le mot de passe choisi est trop faible. Choisissez en un plus complexe."
  override val authErrorFailurePasswordWrong = "Mauvais mot de passe. Veuillez réessayer."
  override val authErrorFailureInvalidEmail = "L'email entré n'est pas valide."
  override val authErrorFailureInvalidPhoneNumber = "Le numéro de téléphone n'est pas valide."
  override val authErrorFailureEmailAlreadyInUse = "Cet email est déjà existant."
  override val authErrorFailureInvalidVerificationCode = "Le code de vérification est incorrect."
  override val authErrorFailureUnknown =
      "Une erreur inconnue s'est produite. Veuillez contacter le support."

  override val teamSelectionHeadline = "Avec quelle entreprise souhaitez-vous vous connecter?"
  override val teamSelectionLogOut = "Se déconnecter"

  override fun teamSelectionMembers(members: Int): String = "$members membres"

  override val teamSelectionNoTeamHeadline = "Aucune entreprise n'est associée avec votre compte."
  override val teamSelectionNoTeamInformation =
      "Si vous devriez faire partie d'une entreprise existante, contactez votre administrateur afin qu'il vous y donne accès. Sinon, vous pouvez créer une nouvelle entreprise."
  override val teamSelectionNewTeamButton = "Créer une entreprise"
  override val teamSelectionExistingTeamButton = "Rejoindre une entreprise"

  override val requestTeamAccessHeadline =
      "Entrez l'identifiant de l'entreprise que vous souhaitez rejoindre."
  override val requestTeamAccessHeadlineSuccess = "Votre demande à été enregistrée."
  override val requestTeamAccessSuccessMessage =
      "L'administrateur de l'entreprise doit valider votre demande. Dès que cela sera fait, vous aurez accès à celle-ci."
  override val requestTeamAccessIdPlaceholder = "Identifiant de l'entreprise"
  override val requestTeamAccessRequestAccessButton = "Rejoindre l'entreprise"
  override val requestTeamAccessRequestFailure =
      "Échec de la demande. Vérifiez que l'identifiant de l'entreprise soit correct."

  override val teamCreationFailure =
      "Désolé, nous n'avons pas pu créer votre entreprise. Veuillez rafraichir la page ou contacter le support technique."
  override val teamCreationMissingData = "Veuillez remplir tous les champs demandés."

  override val newTeamTraakHeadline = "Rejoignez la communauté TRAAK en créant votre entreprise."
  override val newTeamCreateButton = "Créer l'entreprise"
  override val newTeamCancelButton = "Annuler"
  override val newTeamBillingHeadline = "Contact de facturation"
  override val newTeamNamePlaceholder = "Nom de votre entreprise"
  override val newTeamContactNamePlaceholder = "Nom de la personne de contact"
  override val newTeamEmailPlaceholder = "Email de la personne de contact"
  override val newTeamPhonePlaceholder = "Téléphone de la personne de contact"
  override val newTeamAddressPlaceholder = "Adresse de facturation"
  override val newTeamLocalityPlaceholder = "Localité"
  override val newTeamZipPlaceholder = "ZIP"

  override val projectTitleLabel = "Titre du projet"
  override val projectNameLabel = "Nom du client"
  override val projectPhoneLabel = "Téléphone du client"
  override val projectStreetLabel = "Rue"
  override val projectPostalCodeLabel = "Code postal"
  override val projectLocalityLabel = "Localité"
  override val projectLocalityWithZipLabel = "Localité et code postale"
  override val projectTitlePlaceholder = "001 Exemple"
  override val projectNamePlaceholder = "Jean Dupond"
  override val projectPhonePlaceholder = "+41 79 123 45 67"
  override val projectStreetPlaceholder = "Avenue de la Gare 12"
  override val projectPostalCodePlaceholder = "1234"
  override val projectLocalityPlaceholder = "Lausanne"
  override val projectLocalityWithZipPlaceholder = "1234 Lausanne"

  override val projectEmptyTitleError = "Veuillez entrer le titre du projet."
  override val projectSavedError = "Une erreur s'est produite."

  override val newProjectTitle = "Nouveau projet"
  override val newProjectExplanation =
      "Remplissez les informations nécessaires. Vous pourrez toujours les modifier ultérieurement."
  override val newProjectCreateProjectButton = "Créer le projet"

  override val projectOverviewTitle = "Sélectionner un projet"
  override val projectOverviewNewProjectButton = "Nouveau projet"
  override val projectOverviewSearchBarPlaceholder =
      "Rechercher un projet (avec son titre, le nom du client, la localité, ...)"
  override val projectOverviewNoActiveProjects =
      "Vous n'avez actuellement pas de projet. Vous pouvez en créer un en cliquant sur '${projectOverviewNewProjectButton}'."
  override val projectOverviewNoArchivedProjects =
      "Vous n'avez actuellement pas de projets archivés."

  override val projectOverviewNoSearchedProjects = "Aucun projet trouvé..."
  override val projectOverviewSynchronizeBexioTooltipHeader = "Synchroniser bexio"
  override val projectOverviewSynchronizeBexioTooltipParagraph =
      "Vous ne pouvez plus créer de nouveaux projets sur Traak. Mais vous pouvez le faire sur bexio."

  override val projectStatusActive = "Actif"
  override val projectStatusArchived = "Archivé"

  override val projectDetailEmptyAddressTitle = "Aucune adresse enregistrée"
  override val projectDetailEmptyAddressParagraph =
      "Afin d'ajouter les informations manquantes, éditez le projet."
  override val projectDetailAddressSubtitle = "Adresse"
  override val projectDetailEditProjectButton = "Éditer"
  override val projectDetailArchiveButton = "Archiver"
  override val projectDetailRestoreButton = "Restorer"
  override val projectDetailExportProjectButton = "Exporter"
  override val projectDetailSearchPlaceholder = "Rechercher une tâche"
  override val projectDetailHeaderDate = "Date"
  override val projectDetailHeaderUser = "Utilisateur"
  override val projectDetailHeaderTask = "Tâche"
  override val projectDetailHeaderDuration = "Durée"

  override val projectEditionTitle = "Édition de projet"
  override val projectEditionExplanation =
      "Éditez les différents champs puis sauvegardez le projet."
  override val projectEditionSaveButton = "Sauvegarder"
  override val projectEditionCancelButton = "Annuler"

  override val teamOverviewTeamMemberTitle = "Membres de l'entreprise"
  override val teamOverviewSearchPlaceholder = "Recherchez des membres"
  override val teamOverviewNoFilteredMember = "Aucun membre correspondant"
  override val teamOverviewSegButtonActive = "Actif"
  override val teamOverviewSegButtonRemoved = "Supprimé"

  override val teamSettingsInvitationTitle = "Ajouter un nouveau membre"
  override val teamSettingsInvitationExplanation =
      "Afin d'ajouter un nouveau membre à votre entreprise, copiez l'identifiant de votre entreprise ci-dessous et envoyer le à la personne désirée."
  override val teamSettingsInvitationExplanationAfterCopy =
      "Une fois que la personne à envoyé sa demande, vous devrez la valider ci-dessous."
  override val teamSettingsInvitationCopied = "Copié"
  override val teamSettingsRequestsTitle = "Demandes"
  override val teamSettingsNoRequests =
      "Aucune demande pour rejoindre votre entreprise est en cours."
  override val teamSettingsHeaderName = "Nom"
  override val teamSettingsHeaderRole = "Rôle"
  override val teamSettingsRoleAdmin = "Administrateur"
  override val teamSettingsRoleProjectManager = "Chef de projet"
  override val teamSettingsRoleMember = "Utilisateur"
  override val teamSettingsRoleRemoved = "Désactivé"

  override val memberDetailHeaderProject = "Projet"
  override val memberDetailSearchPlaceholder = "Rechercher une tâche"
  override val memberDetailExport = "Exporter"

  override val bexioIntegrationHeadline = "Connecter Traak avec bexio"
  override val bexioIntegrationStoringCredentials = "Nous enregistrons vos identifiants."
  override val bexioIntegrationSynchronizing =
      "Nous synchronisons votre compte avec bexio. Cette opération peut durer, veuillez laisser la page ouverte."
  override val bexioIntegrationError = "Une erreur s'est produite, veuillez recharger la page."
  override val bexioIntegrationDone =
      "Nous avons fini de synchroniser votre compte. Vous allez être redirigés."
  override val bexioIntegrationSideBarConnection = "Connexion"
  override val bexioIntegrationSideBarTeamSelection = "Choix d'une entreprise"
  override val bexioIntegrationSideBarAuthorization = "Autorisation"
  override val bexioIntegrationSideBarSynchronization = "Synchronisation"
  override val bexioIntegrationConnectionTitle = "Connectez vous avec votre compte bexio"
  override val bexioIntegrationConnectionExplanation =
      "Pour connecter Traak et bexio, commencez par vous connecter avec votre compte bexio."
  override val bexioIntegrationConnectionBexioExplanation =
      "Si vous n'avez pas encore de compte chez bexio, profitez de notre partenariat pour obtenir 1 mois d'essai ainsi que 30% de réduction sur votre première année. Pour plus d'informations, veuillez vous référez à notre"
  override val bexioIntegrationConnectionBexioLink = "page d'information"
  override val bexioIntegrationTeamSelectionTitle = "Choisissez votre équipe"
  override val bexioIntegrationTeamSelectionExplanation =
      "Choisissez l'équipe que vous voulez connecter à bexio. Si vous ne voyez pas votre équipe, vous pouvez en créer une nouvelle."
  override val bexioIntegrationAuthorizationTitle = "Autorisez l'accès aux données"
  override val bexioIntegrationAuthorizationExplanation =
      "Pour synchroniser votre compte bexio avec Traak, nous avons besoin d'avoir accès à vos données. Lorsque bexio vous le demandera, veuillez autoriser la demande."
  override val bexioIntegrationAuthorizationButton = "Autoriser"

  override val taskItemMenuEdit = "Modifier"
  override val taskItemMenuDelete = "Supprimer"
  override val taskItemDeletionCancellableSnackbar = "Annuler la suppression de la tâche ?"

  override val memberMenuEdit = "Modifier"
  override val memberMenuDelete = "Supprimer"

  override val memberEditionTitle = "Éditer le nom d'un membre de votre entreprise"
  override val memberEditionFirstNamePlaceholder = "Prénom"
  override val memberEditionLastNamePlaceholder = "Nom"
  override val memberEditionReset = this.reset
  override val memberEditionSave = this.save

  override val recapListTitle = "Communications"
  override val recapListSeparatorTitle: (String, String) -> String = { start, end ->
    "Du $start au $end"
  }
  override val recapListEmptyRecap = "Aucune communication"

  override val accountTitle = "Préférences personnelles"
  override val accountDisplaySettingsTitle = "Paramètres d'affichage"
  override val accountOrderByFirstNameTitle = "Ordonner par prénom"
  override val accountOrderByFirstNameExplanationActive =
      "Lorsqu'une liste d'utilisateur sera présentée, celle-ci sera ordonnée par le prénom du collaborateur."
  override val accountOrderByFirstNameExplanationInactive =
      "Lorsqu'une liste d'utilisateur sera présentée, celle-ci sera ordonnée par le nom de famille du collaborateur."
  override val accountShowFirstNameFirstTitle = "Afficher le prénom en premier"
  override val accountShowFirstNameFirstExplanationActive =
      "Le prénom de l'utilisateur sera affiché en premier: Jean Dupont"
  override val accountShowFirstNameFirstExplanationInactive =
      "Le nom de l'utilisateur sera affiché en premier: Dupont Jean"

  override val searchableCheckboxDeselectAll = "Tout désélectionner"
  override val searchableCheckboxSelectAll = "Tout sélectionner"

  override val peopleChipTitleEverybody: String = "Tout le monde"
  override val peopleChipTitleNobody: String = "Personne"

  override fun peopleChipTitle(numberOfPeople: Int): String {
    if (numberOfPeople <= 1) {
      throw IllegalArgumentException("numberOfPeople should be greater than 1.")
    }

    return "$numberOfPeople personnes"
  }

  override val peopleChipSearchPlaceholder = "Rechercher un collaborateur"

  override val projectsChipSearchPlaceholder = "Rechercher un projet"
  override val projectsChipTitleAll: String = "Tous les projets"
  override val projectsChipTitleNone: String = "Aucun projet"

  override fun projectsChipTitle(numberOfProjects: Int): String {
    if (numberOfProjects <= 1) {
      throw IllegalArgumentException("numberOfProjects should be greater than 1.")
    }

    return "$numberOfProjects projets"
  }

  override val allTasksTitle = "Toutes les tâches"
  override val allTasksSearchPlaceholder = "Rechercher dans toutes les tâches..."
}
