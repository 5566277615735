package ui.strings

object EnglishStrings : LocalizedStrings {
  override val mondayAbbr = "Mon"
  override val tuesdayAbbr = "Tue"
  override val wednesdayAbbr = "Wed"
  override val thursdayAbbr = "Thu"
  override val fridayAbbr = "Fri"
  override val saturdayAbbr = "Sat"
  override val sundayAbbr = "Sun"

  override val january = "January"
  override val february = "February"
  override val march = "March"
  override val april = "April"
  override val may = "May"
  override val june = "June"
  override val july = "July"
  override val august = "August"
  override val september = "September"
  override val october = "October"
  override val november = "November"
  override val december = "December"

  override val hourSuffix = "h"
  override val minuteSuffix = "m"

  override val description = "description"
  override val success = "success"
  override val save = "save"
  override val reset = "reset"
  override val cancel = "cancel"
  override val help = "help"
  override val continueStr = "continue"
  override val or = "or"
  override val export = "export"

  override val exampleFullName = "John Doe"

  override val taskCreatorTitle = "Add a new task"

  override val taskTableTotalTime = "Total time"

  override val navMenuTasks = "Tasks"
  override val navMenuProjects = "Projects"
  override val navMenuTeam = "Team"
  override val navMenuDetailTeamManagement = "Team management"
  override val navMenuDetailSwitchTeams = "Switch teams"
  override val navMenuDetailSwitchLanguage = "Switch languages"
  override val navMenuDetailHelp = "Help"
  override val navMenuDetailLogOut = "Log out"
  override val teamSelectionExistingTeamButton = "Existing team"

  override val loginSubtitle = "Welcome back to TRAAK"
  override val loginEmailPlaceholder = "Email"
  override val loginPasswordPlaceholder = "Password"
  override val loginLogInButton = "Log In"
  override val loginLogInBexioButton = "Log In with bexio"
  override val loginNoAccount = "Don't have an account yet?"
  override val loginCreateAccount = "Create one now"
  override val loginSeparationText = "Or"
  override val loginExplanationIdentifying =
      "In order to connect, please enter your email address or your phone number."
  override val loginExplanationValidatingPhone =
      "Please enter the code you have received by text message."
  override val loginExplanationWaitingForEmailClick =
      "You should have received an email containing a link. Please click on it in order to connect. If you don’t find the email, wait a few minutes or check your spam folder."
  override val loginIdentifierError =
      "The identifier should look something like ‘john.doe@example.org’ or ‘+41 79 123 45 67’."
  override val loginConnectionTitle = "Connection"
  override val loginIdentifierFormIdentifierLabel = "Email or phone number"
  override val loginIdentifierFormIdentifierPlaceholder = "john.doe@example.org or +41 79 123 45 67"
  override val loginIdentifierFormIdentifierContinueButton = continueStr
  override val loginValidationFormValidationPlaceholder = "Validation code"
  override val loginValidationFormValidationLabel = "Validation code"
  override val loginValidationFormVerifyButton = "Verify"

  override val setupPanelTitle = "Setting up  your account"
  override val setupProgressionName = "Name"
  override val setupProgressionEmail = "Email address"
  override val setupProgressionPhone = "Phone number"

  override val setupNameTitle = "What is your name?"
  override val setupNameExplanation = "Please enter your full name below."
  override val setupNameFormNameLabel = "Full name"
  override val setupNameFormNamePlaceholder = exampleFullName
  override val setupNameFormContinueButton = continueStr

  override val setupPhoneExplanationRetrieving: String =
      "Please enter your phone number below. You will receive a sms code in order to validate this number."
  override val setupPhoneExplanationValidating: String =
      "Please enter the code you have received by text message."
  override val setupPhoneTitle: String = "Setting up your account - Phone number"
  override val setupPhoneIdentifierFormPhoneLabel: String = "Phone number"
  override val setupPhoneIdentifierFormPhonePlaceholder: String = "+41 79 123 45 67"
  override val setupPhoneIdentifierFormContinueButton: String = "Continue"
  override val setupPhoneValidationFormValidationCodePlaceholder: String = "Validation code"
  override val setupPhoneValidationFormValidationCodeLabel: String = "Validation code"
  override val setupPhoneValidationFormVerifyButton: String = "Verify"
  override val setupEmailTitle = "What is your email address ?"
  override val setupEmailExplanation =
      "Once you have entered your email address below, you will receive an email. In order to connect, please click the link that you will have received."
  override val setupEmailFormEmailLabel = "Email"
  override val setupEmailFormEmailPlaceholder = "john.doe@example.org"
  override val setupEmailFormContinueButton = continueStr

  override val registerTraakHeadline = "Join the TRAAK community by registering"
  override val registerEmailPlaceholder = "Email"
  override val registerFirstNamePlaceholder = "First Name"
  override val registerLastNamePlaceholder = "Last Name"
  override val registerPasswordPlaceholder = "Password"
  override val registerPasswordConfirmationPlaceholder = "Password confirmation"
  override val registerButton = "Register"
  override val registerGoBackButton = "Go back"
  override val registerErrorEmptyName = "Please enter your full name."
  override val registerSuccess =
      "Thank you for creating an account. You will be redirected to login."

  override val authErrorFailureInternal = "Sorry, we had an internal error. Please refresh."
  override val authErrorFailureUserUnknown = "This user is not known."
  override val authErrorFailureUserAlreadyRegistered = "This user is already registered."
  override val authErrorFailurePasswordTooWeak =
      "The password is too weak. Chose a more complex one."
  override val authErrorFailurePasswordWrong = "Wrong password. Please try again"
  override val authErrorFailureInvalidEmail = "This email is invalid."
  override val authErrorFailureInvalidPhoneNumber = "This phone number is invalid."
  override val authErrorFailureEmailAlreadyInUse = "This email is already in use."
  override val authErrorFailureInvalidVerificationCode = "The verification code is invalid."
  override val authErrorFailureUnknown = "An unknown failure occurred. Please contact the support."

  override val teamSelectionHeadline = "Please chose the team to which you wish to connect to."
  override val teamSelectionLogOut = "Log out"

  override fun teamSelectionMembers(members: Int): String = "$members members"

  override val teamSelectionNoTeamHeadline = "No team is currently linked to your account."
  override val teamSelectionNoTeamInformation =
      "If you should be a part of an existing team, please contact your administrator so that he might give you access. Otherwise, you can create a new team."
  override val teamSelectionNewTeamButton = "New Team"

  override val requestTeamAccessHeadline =
      "Enter the team identifier. This identifier should have been provided to you by your administrator."
  override val requestTeamAccessHeadlineSuccess = "Your request has been saved."
  override val requestTeamAccessSuccessMessage =
      "The team administrator must validate your request. Once this has been done, you will have access to the team."
  override val requestTeamAccessIdPlaceholder = "Team identifier"
  override val requestTeamAccessRequestAccessButton = "Request access"
  override val requestTeamAccessRequestFailure = "Request denied. Please check the team identifier."

  override val teamCreationFailure =
      "Sorry, we failed to create your team. Please refresh or contact the support."
  override val teamCreationMissingData = "Please fill out all the requested information."

  override val newTeamTraakHeadline = "Be a part of the TRAAK community by creating your own team."
  override val newTeamCreateButton = "Create team"
  override val newTeamCancelButton = "Cancel"
  override val newTeamBillingHeadline = "Billing information"
  override val newTeamNamePlaceholder = "Team name"
  override val newTeamContactNamePlaceholder = "Contact's name"
  override val newTeamEmailPlaceholder = "Contact's email"
  override val newTeamPhonePlaceholder = "Contact's phone number"
  override val newTeamAddressPlaceholder = "Billing address"
  override val newTeamLocalityPlaceholder = "Locality"
  override val newTeamZipPlaceholder = "ZIP Code"

  override val projectTitleLabel = "Project's title"
  override val projectNameLabel = "Client's name"
  override val projectPhoneLabel = "Client's phone number"
  override val projectStreetLabel = "Street"
  override val projectPostalCodeLabel = "ZIP Code"
  override val projectLocalityLabel = "Locality"
  override val projectLocalityWithZipLabel = "Locality and ZIP code"
  override val projectTitlePlaceholder = "001 Example"
  override val projectNamePlaceholder = exampleFullName
  override val projectPhonePlaceholder = "+41 79 123 45 67"
  override val projectStreetPlaceholder = "Avenue de la Gare 12"
  override val projectPostalCodePlaceholder = "1234"
  override val projectLocalityPlaceholder = "Lausanne"
  override val projectLocalityWithZipPlaceholder = "1234 Lausanne"

  override val projectEmptyTitleError = "Please enter the title of the project"
  override val projectSavedError = "An error occured."

  override val newProjectTitle = "New project"
  override val newProjectExplanation =
      "Fill in the necessary information. You can always modify them later."
  override val newProjectCreateProjectButton = "Create project"

  override val projectOverviewTitle = "Select a project"
  override val projectOverviewNewProjectButton = "New project"
  override val projectOverviewSearchBarPlaceholder =
      "Search projects (by its title, the client's name, the locality, ...)"
  override val projectOverviewNoActiveProjects =
      "You currently have no projects. You can create one by clicking on the '${projectOverviewNewProjectButton}' button."
  override val projectOverviewNoArchivedProjects = "You currently have no archived projects."
  override val projectOverviewNoSearchedProjects = "No project found..."
  override val projectOverviewSynchronizeBexioTooltipHeader = "Synchronize bexio"
  override val projectOverviewSynchronizeBexioTooltipParagraph =
      "You cannot create new projects on Traak. However, you can on bexio."

  override val projectStatusActive = "Active"
  override val projectStatusArchived = "Archived"

  override val projectDetailEmptyAddressTitle = "No project address"
  override val projectDetailEmptyAddressParagraph =
      "In order to add this information, please edit the project."
  override val projectDetailAddressSubtitle = "Address"
  override val projectDetailEditProjectButton = "Edit project"
  override val projectDetailArchiveButton = "Archive"
  override val projectDetailRestoreButton = "Restore"
  override val projectDetailExportProjectButton = "Export"
  override val projectDetailSearchPlaceholder = "Search a task"
  override val projectDetailHeaderDate = "Date"
  override val projectDetailHeaderUser = "User"
  override val projectDetailHeaderTask = "Task"
  override val projectDetailHeaderDuration = "Duration"

  override val projectEditionTitle = "Edit a project"
  override val projectEditionExplanation = "Modify the different attributes, then save."
  override val projectEditionSaveButton = "Save"
  override val projectEditionCancelButton = "Cancel"

  override val teamOverviewTeamMemberTitle = "Team Members"
  override val teamOverviewSearchPlaceholder = "Search members"
  override val teamOverviewNoFilteredMember = "No corresponding member"
  override val teamOverviewSegButtonActive = "Active"
  override val teamOverviewSegButtonRemoved = "Removed"

  override val teamSettingsInvitationTitle = "Add a new member"
  override val teamSettingsInvitationExplanation =
      "In order to add a new member, copy your team identifier below and send it to the desired person."
  override val teamSettingsInvitationExplanationAfterCopy =
      "Once the other person has made a request to be a member of your team, you will need to validate it just below."
  override val teamSettingsRequestsTitle = "Requests"
  override val teamSettingsInvitationCopied = "Copied"
  override val teamSettingsNoRequests = "No requests at this time."
  override val teamSettingsHeaderName = "Name"
  override val teamSettingsHeaderRole = "Role"
  override val teamSettingsRoleAdmin = "Administrator"
  override val teamSettingsRoleProjectManager = "Manager"
  override val teamSettingsRoleMember = "User"
  override val teamSettingsRoleRemoved = "Disabled"
  override val teamSettingsProjectsUpdateTitle = "Transfer projects to the next year"
  override val teamSettingsProjectsUpdateExplanation =
      "If you wish to transfer projects to the next year, follow the instructions in our project migrator."
  override val teamSettingsProjectsUpdateLink = "go to the project migrator page"

  override val memberDetailHeaderProject = "Project"
  override val memberDetailSearchPlaceholder = "Search a task"
  override val memberDetailExport = "Export"

  override val bexioIntegrationHeadline = "Connect Traak to bexio"
  override val bexioIntegrationStoringCredentials = "We are storing your credentials."
  override val bexioIntegrationSynchronizing =
      "We are synchronizing your account with bexio. Please leave this page open, this may take a while."
  override val bexioIntegrationError = "Sorry, an error occurred. Please reload this page."
  override val bexioIntegrationDone =
      "We are done synchronizing your account. You will be redirected."
  override val bexioIntegrationSideBarConnection = "Connection"
  override val bexioIntegrationSideBarTeamSelection = "Team selection"
  override val bexioIntegrationSideBarAuthorization = "Authorization"
  override val bexioIntegrationSideBarSynchronization = "Synchronization"
  override val bexioIntegrationConnectionTitle = "Log in with your bexio account"
  override val bexioIntegrationConnectionExplanation =
      "In order to connect Traak and bexio together, start by logging in with your bexio account."
  override val bexioIntegrationConnectionBexioExplanation =
      "If you don't have a bexio account and would like one, you can benefit from our partnership with bexio: after your free one month trial, you will have a 30% reduction on your first year. To know more about our partnership with bexio, you can go on our"
  override val bexioIntegrationConnectionBexioLink = "information page"
  override val bexioIntegrationTeamSelectionTitle = "Pick your team"
  override val bexioIntegrationTeamSelectionExplanation =
      "Choose the team that you wish to connect to bexio. If you don't see the one you want, you can always create a new team."
  override val bexioIntegrationAuthorizationTitle = "Authorize data access"
  override val bexioIntegrationAuthorizationExplanation =
      "In order to synchronize your bexio account with Traak, we need access to your bexio data. This is why bexio will ask you if you agree to share your data with Traak."
  override val bexioIntegrationAuthorizationButton = "Authorize"

  override val taskItemMenuEdit = "Edit"
  override val taskItemMenuDelete = "Delete"
  override val taskItemDeletionCancellableSnackbar = "Cancel task deletion ?"

  override val memberMenuEdit = "Edit"
  override val memberMenuDelete = "Delete"

  override val memberEditionTitle = "Edit the name of a member of your company"
  override val memberEditionFirstNamePlaceholder = "First name"
  override val memberEditionLastNamePlaceholder = "Last name"
  override val memberEditionReset = this.reset
  override val memberEditionSave = this.save

  override val recapListTitle = "Communications"
  override val recapListSeparatorTitle: (String, String) -> String = { start, end ->
    "From the $start to $end"
  }
  override val recapListEmptyRecap = "No communication"

  override val accountTitle = "Personal Settings"
  override val accountDisplaySettingsTitle = "Display settings"
  override val accountOrderByFirstNameTitle = "Order by first name"
  override val accountOrderByFirstNameExplanationActive =
      "When a list of users is presented, it will be ordered by the first name of the collaborator."
  override val accountOrderByFirstNameExplanationInactive =
      "When a list of users is presented, it will be ordered by the last name of the collaborator."
  override val accountShowFirstNameFirstTitle = "Show first name first"
  override val accountShowFirstNameFirstExplanationActive =
      "The user's first name will be shown first: John Doe"
  override val accountShowFirstNameFirstExplanationInactive =
      "The user's last name will be shown first: Doe John"

  override val searchableCheckboxDeselectAll = "Deselect all"
  override val searchableCheckboxSelectAll = "Select all"

  override val peopleChipTitleEverybody: String = "Everybody"
  override val peopleChipTitleNobody: String = "Nobody"
  override val peopleChipSearchPlaceholder = "Search for a collaborator"

  override fun peopleChipTitle(numberOfPeople: Int): String {
    if (numberOfPeople <= 1) {
      throw IllegalArgumentException("numberOfPeople should be greater than 1.")
    }

    return "$numberOfPeople persons"
  }

  override val projectsChipSearchPlaceholder = "Search for a project"
  override val projectsChipTitleAll: String = "Every project"
  override val projectsChipTitleNone: String = "No project"

  override fun projectsChipTitle(numberOfProjects: Int): String {
    if (numberOfProjects <= 1) {
      throw IllegalArgumentException("numberOfProjects should be greater than 1.")
    }

    return "$numberOfProjects projects"
  }

  override val allTasksTitle = "All tasks"
  override val allTasksSearchPlaceholder = "Search in all tasks..."

  override val projectsUpdateSelectTitle = "Transfer projects to the next year"
  override val projectsUpdateSelectExplanation =
      "This tool allows you to prepare the projects you want for the next year. Once the process is completed, the current projects will be archived, and new corresponding projects will be created."
  override val projectsUpdateSelectExplanation2 =
      "Note, this tool only works with projects that have an 'annualized' name (e.g., '2024.001 - Workshop' or '24.001 / Workshop')."
  override val projectsUpdateSelectSearchPlaceholder = "Search for a project"
  override val projectsUpdateApplyTitle = "Preparing projects"
  override val projectsUpdateApplyLoading = "Please wait"
  override val projectsUpdateApplyLoadingSteps = "The projects are being processed."

  override fun projectsUpdateBottomBarSelectedProjects(amount: Int): String {
    return when (amount) {
      0 -> "No project selected"
      1 -> "$amount project selected"
      else -> "$amount projects selected"
    }
  }

  override val projectsUpdateBottomBarUpdateButton = "Prepare the projects"
  override val projectsUpdateFinishedTitle = "The project transfer is complete"

  override fun projectsUpdateFinishedSuccessfullyApplied(amount: Int): String {
    return when (amount) {
      0 -> "No project processed"
      1 -> "The project was successfully processed"
      else -> "$amount projects were successfully processed"
    }
  }

  override fun projectsUpdateFinishedUnsuccessfullyApplied(amount: Int): String {
    return when (amount) {
      0 -> "No project processed"
      1 -> "The project could not be processed correctly."
      else -> "$amount projects could not be processed correctly"
    }
  }

  override val projectsUpdateFinishedUnsuccessfulManualUpdate =
      "Please perform the desired actions manually for the affected projects."
  override val projectsUpdateStateMessageInitial = "Preparation in progress"

  override fun projectsUpdateStateMessagePreparing(projectName: String): String =
      "Preparing '$projectName'"

  override fun projectsUpdateStateMessageError(projectName: String): String =
      "Error for the project '$projectName'"

  override fun projectsUpdateStateMessageCreatingProject(projectName: String): String =
      "Creating the new project: '$projectName'"

  override fun projectsUpdateStateMessageArchivingOldProject(projectName: String): String =
      "Archiving the old project '$projectName'"

  override fun projectsUpdateStateErrorDto(projectId: String): String =
      "No DTO for project $projectId. Please contact your administrator"

  override fun projectsUpdateStateErrorProjectCreation(projectName: String, error: String): String =
      "The new project '$projectName' could not be saved: $error"

  override fun projectsUpdateStateErrorArchivingOldProject(
      projectName: String,
      error: String
  ): String = "The project '$projectName' could not be archived: $error"

  override fun projectsUpdateStateErrorPatternDoesNotMatch(projectName: String): String =
      "The project '$projectName' does not follow the required naming convention for automatic processing."
}
