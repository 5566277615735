package ui.screens.projectsUpdate

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateListOf
import androidx.compose.runtime.mutableStateMapOf
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.setValue
import api.traak.Project
import api.traak.StorageResult
import api.traak.Team
import api.traak.dto.CreateProjectDTO
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import ui.strings.LocalStrings
import ui.strings.LocalizedStrings

private const val STEP_DELAY = 100L

data class ProjectUpdate(
    val old: Project,
    val dto: CreateProjectDTO?,
    val error: String?,
)

class ProjectsUpdateStateHolder(
    private val team: Team,
    private val coroutineScope: CoroutineScope,
    private val strings: LocalizedStrings,
) : ProjectsUpdateState {
  private var _stage: Stage by mutableStateOf(Stage.Select)
  private var _loading by mutableStateOf(true)
  private var _allProjects: List<Project> = listOf()
  private var _projects: MutableMap<String, SelectableProject> = mutableStateMapOf()
  private val _selectedProjects
    get() = _projects.filter { (_, v) -> v.selected }

  init {
    coroutineScope.launch {
      team.getProjects(Project.Status.Active).collect { projects ->
        _allProjects = projects

        val m = mutableMapOf<String, Project>()
        projects.forEach { m[it.id.raw] = it }

        val newProjects = mutableStateMapOf<String, SelectableProject>()
        m.forEach { (key, newValue) ->
          val oldValue = _projects[key]

          if (oldValue != null) {
            newProjects[key] =
                SelectableProject(
                    id = oldValue.id,
                    title = newValue.title,
                    selected = oldValue.selected,
                )
          } else {
            newProjects[key] =
                SelectableProject(
                    id = newValue.id.raw,
                    title = newValue.title,
                    selected = false,
                )
          }
        }

        _projects = newProjects
        _loading = false
      }
    }
  }

  override val stage: Stage
    get() = _stage

  override val allProjectsAreSelected: Boolean
    get() = _selectedProjects.size == _projects.size

  override val loading: Boolean
    get() = _loading

  override val projects: List<SelectableProject>
    get() =
        _projects.values
            .toList()
            .sortedBy { it.title }
            .filter { it.title.contains(search, ignoreCase = true) }

  override fun toggle(project: SelectableProject) {
    _projects[project.id]?.let {
      _projects[project.id] =
          SelectableProject(
              id = it.id,
              title = it.title,
              selected = !it.selected,
          )
    }
  }

  override fun selectAll() {
    _projects.forEach { (k, p) ->
      _projects[k] =
          SelectableProject(
              id = p.id,
              title = p.title,
              selected = true,
          )
    }
  }

  override fun deselectAll() {
    _projects.forEach { (k, p) ->
      _projects[k] =
          SelectableProject(
              id = p.id,
              title = p.title,
              selected = false,
          )
    }
  }

  override var search by mutableStateOf("")

  override val selectedProjectsAmount: Int
    get() = _selectedProjects.size

  override fun updateSelectedProjects() {
    _stage = Stage.Apply

    val selectedIds = _selectedProjects.keys
    val selected = _allProjects.filter { selectedIds.contains(it.id.raw) }

    selected.forEach { oldProject ->
      val update = prepareProject(oldProject)
      if (update != null) {
        updateData[oldProject.id.raw] = update
      } else {
        _updateErrors.add(strings.projectsUpdateStateErrorPatternDoesNotMatch(oldProject.title))
      }
    }

    coroutineScope.launch {
      for ((key, data) in updateData) {
        _currentUpdate++
        update(key, data)
      }

      _stage = Stage.Finished
    }
  }

  private val updateData = mutableStateMapOf<String, ProjectUpdate>()
  private val _updateErrors = mutableStateListOf<String>()

  private var _currentUpdate by mutableStateOf(0)
  private var _currentMessage by mutableStateOf(strings.projectsUpdateStateMessageInitial)

  override val currentUpdate: Int
    get() = _currentUpdate

  override val totalUpdates: Int
    get() = updateData.size

  override val updateErrors: List<String>
    get() = _updateErrors

  override val currentMessage: String
    get() = _currentMessage

  private suspend fun update(id: String, update: ProjectUpdate) {
    _currentMessage = strings.projectsUpdateStateMessagePreparing(update.old.title)
    delay(STEP_DELAY)
    if (update.error != null) {
      _currentMessage = strings.projectsUpdateStateMessageError(update.old.title)
      _updateErrors.add("${update.error} - ($id)")
      return
    }

    if (update.dto == null) {
      _currentMessage = strings.projectsUpdateStateMessageError(update.old.title)
      _updateErrors.add(strings.projectsUpdateStateErrorDto(update.old.id.raw))
      return
    }

    _currentMessage = strings.projectsUpdateStateMessagePreparing(update.dto.title)
    delay(STEP_DELAY)
    val creationRes = team.createProject(update.dto)
    if (creationRes != StorageResult.Success) {
      _updateErrors.add(
          strings.projectsUpdateStateErrorProjectCreation(
              update.dto.title,
              creationRes.toString(),
          ),
      )
      delay(STEP_DELAY)
      return
    }

    _currentMessage = strings.projectsUpdateStateMessageArchivingOldProject(update.old.title)
    delay(STEP_DELAY)
    val archiveRes = update.old.changeStatus(to = Project.Status.Archived)
    if (archiveRes != StorageResult.Success) {
      _updateErrors.add(
          strings.projectsUpdateStateErrorArchivingOldProject(
              update.old.title,
              archiveRes.toString(),
          ),
      )
      return
    }
  }
}

fun prepareProject(old: Project): ProjectUpdate? {
  val newTitle: String

  val longPrefix = old.title.take(4).toIntOrNull()
  val smallPrefix = old.title.take(2).toIntOrNull()

  val prefix = longPrefix ?: smallPrefix
  if (prefix != null) {
    newTitle = (prefix + 1).toString() + old.title.removePrefix(prefix.toString())
  } else {
    return null
  }

  val projectDTO =
      CreateProjectDTO(
          title = newTitle,
          name = old.address.name,
          phone = old.address.phone,
          postalCode = "",
          locality = old.address.locality,
          street = old.address.place,
      )

  return ProjectUpdate(old, projectDTO, null)
}

@Composable
fun rememberProjectsUpdateState(
    team: Team,
    coroutineScope: CoroutineScope = rememberCoroutineScope(),
    strings: LocalizedStrings = LocalStrings.current,
): ProjectsUpdateState {
  return remember(team, coroutineScope) {
    ProjectsUpdateStateHolder(
        team = team,
        coroutineScope = coroutineScope,
        strings = strings,
    )
  }
}
